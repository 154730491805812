* {
    margin: 0;
	padding: 0;
    scroll-behavior: smooth;
}



.grad__bg {
    /* background:#F5F5F5;
	background: linear-gradient(98deg, rgba(86,189,247,1) 0%, rgba(70,155,243,1) 100%);  */

        /* ff 3.6+ */
    background:-moz-linear-gradient(98deg, #56BDF7 0%, #469BF3 100%); 

        /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(98deg, #56BDF7 0%,#469BF3 100%);
    
        /* opera 11.10+ */
    background:-o-linear-gradient(98deg, #56BDF7 0%, #469BF3 100%);
    
        /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ee82ee', endColorstr='469BF3', GradientType=0 );
    
        /* ie 10+ */
    background:-ms-linear-gradient(98deg, #56BDF7 0%, #469BF3 100%);
    
        /* global 94%+ browsers support */
    background:linear-gradient(98deg, #56BDF7 0%, #469BF3 100%);
}


.section_padding {
    padding: 4rem 6rem;
}

.section_margin {
    margin: 4rem 6rem;
}

