.unimy__networking-contents_cbox {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 30px;
    align-items: center;
    /* padding-left: 63px;
    padding-right: 77px;
    padding-top: 113px;
    padding-bottom: 124px; */
    width: 518px;
    height: 493px;
    margin: 14px;
    cursor: pointer;
    transition: all 1.5s ease-in-out;

}

.unimy__networking-contents_cbox h1 {
    font-family: 'Futura', Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    align-items: center;
    text-align: center;
    flex-direction: column;
    font-size: 64px;
    line-height: 85px;
    width: 378px;
    height: 170px;
    padding-left: 1em;
    padding-top: 1.5em;
    color: #FFFFFF;
    transition: all 1s ease-in-out;
    transition-delay: 0.5s;

}

.unimy__networking-contents_cbox p {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease-in-out;
    transition-delay: 1s;
}

.unimy__networking-contents_cbox:focus-within {
    width: 700px;
    height: 1520px;
    text-align: center;
    padding-left: 44px;
    padding-right: 44px;

}

.unimy__networking-contents_cbox:focus-within h1 {
    flex-direction: none;
    width: 646px;
    text-align: center;
    align-items: center;
    padding-left: .6em;
}

.unimy__networking-contents_cbox:focus-within p {
    display: flex;
    font-family: 'Futura', Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 42px;
    text-align: center;
    text-align: justify;
    text-justify: inter-word;
    color: #00376F;
    width: 700px;
    opacity: 1;
    visibility: visible;
    transition: opacity 1s ease-in-out;
    transition-delay: 1s;

}

/* RESPOINSIVE */

@media screen and (max-width: 1050px) {

    .unimy__networking-contents_cbox {
        border-radius: 23px;
        width: 389px;
        height: 370px;
        margin: 11px;

    }

    .unimy__networking-contents_cbox h1 {
        font-size: 48px;
        line-height: 64px;
        width: 284px;
        height: 100px;
    }

    .unimy__networking-contents_cbox:focus-within {
        border-radius: 23px;
        width: 450px;
        height: 1300px; 
    }

    .unimy__networking-contents_cbox:focus-within h1 {
        width: 425px;
        padding-right: 0.5em;
        padding-left: 0;
    }

    .unimy__networking-contents_cbox:focus-within p {
        font-size: 24px;
        line-height: 32px;
        width: 450px;
    }
}

@media screen and (max-width: 850px) {

    .unimy__networking-contents_cbox {
        border-radius: 17px;
        width: 288px;
        height: 274px;
        margin: 8px;
    }

    .unimy__networking-contents_cbox h1 {
        font-size: 36px;
        line-height: 47px;
        width: 210px;
        height: 94px;
    }

    .unimy__networking-contents_cbox:focus-within {
        border-radius: 17px;
        width: 350px;
        height: 930px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .unimy__networking-contents_cbox:focus-within h1 {
        width: 300px;
        padding-left: .7em;
    }

    .unimy__networking-contents_cbox:focus-within p {
        font-size: 18px;
        line-height: 23px;
        width: 350px;
    }
}

@media screen and (max-width: 650px) {

    .unimy__networking-contents_cbox {
        border-radius: 10px;
        width: 173px;
        height: 164px;
        margin: 5px;
    }

    .unimy__networking-contents_cbox h1 {
        font-size: 21px;
        line-height: 28px;
        width: 126px;
        height: 57px;
    }

    .unimy__networking-contents_cbox:focus-within {
        border-radius: 10px;
        width: 210px;
        height: 580px; 
        padding-left: 15px;
        padding-right: 15px;
    }

    .unimy__networking-contents_cbox:focus-within h1 {
        width: 200px;
        padding-right: .7em;
        padding-left: 0em;
    }

    .unimy__networking-contents_cbox:focus-within p {
        font-size: 11px;
        line-height: 14px;
        width: 210px;
    } 
}

@media screen and (max-width: 490px) {

    .unimy__networking-contents_cbox {
        border-radius: 8px;
        width: 130px;
        height: 123px;
        margin: 4px;
    }

    .unimy__networking-contents_cbox h1 {
        font-size: 16px;
        line-height: 21px;
        width: 95px;
        height: 43px;
    }

    .unimy__networking-contents_cbox:focus-within {
        border-radius: 8px;
        padding-left: 11px;
        padding-right: 11px;
        width: 150px;
        height: 450px;

    }

    .unimy__networking-contents_cbox:focus-within h1 {
        width: 150px;
        padding-right: 0.8em;
        padding-left: 0em;
    }

    .unimy__networking-contents_cbox:focus-within p {
        font-size: 8px;
        line-height: 11px;
        width: 150px;
    }

}
