@import "./lbox.css";
@import "./cbox.css";
@import "./rbox.css";


.unimy__networking {
    display: flex;
}

.unimy__networking-contents {
    margin-top: 9em;
    margin-bottom: 5em;
    flex: 1;
    display: flex;
    justify-content: center;
}

/* RESPONSIVE */

@media screen and (max-width: 1050px) {

    .unimy__networking-contents {
        margin-top: 7em;
        margin-bottom: 6.67em;
    }
}

@media screen and (max-width: 850px) {

    .unimy__networking-contents {
        margin-top: 5em;
        margin-bottom: 2.78em;
    }
}

@media screen and (max-width: 650px) {

    .unimy__networking-contents {
        margin-top: 3em;
        margin-bottom: 1.67em;
    }
}

@media screen and (max-width: 490px) {

    .unimy__networking-contents {
        margin-top: 2.25em;
        margin-bottom: 1.25em;   
    } 
}


