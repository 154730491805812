.unimy__networking-contents_lbox {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 30px;
    transition: 1.5s;
    width: 300px;
    height: 300px;
    align-items: center;
    justify-content: center;
    margin-top: 12.9em;
    transition: all 1.5s ease-in-out;

} 

.unimy__networking-contents_lbox h2 {
    font-family: 'Futura', Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    align-items: center;
    text-align: center;
    flex-direction: column;
    font-size: 45px;
    line-height: 60px;
    text-align: center;
    color: #FFFFFF;
    padding-left: 0.1rem;
    padding-top: 1em;
    transition: all 2s ease-in-out;
    transition-delay: 0.5s;

}

.unimy__networking-contents_lbox p {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease-in-out;
    transition-delay: 1s;
}

.unimy__networking-contents_lbox:focus-within {
    padding-left: 44px;
    padding-right: 44px;
    width: 780px;
    height: 1060px;
    text-align: center;
    margin-left: 14px;
}


.unimy__networking-contents_lbox:focus-within h2 {
    flex-direction: none;
    width: 646px;
    text-align: center;
    align-items: center;
    padding-left: 1em;
    padding-bottom: 1em;
}

.unimy__networking-contents_lbox:focus-within p{
    display: flex;
    font-family: 'Futura', Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 42px;
    text-align: center;
    text-align: justify;
    text-justify: inter-word;
    color: #00376F;
    width: 780px;
    opacity: 1;
    visibility: visible;
    transition: opacity 1s ease-in-out;
    transition-delay: 1s;
}

/* RESPONSIVE */

@media screen and (max-width: 1050px) {

    .unimy__networking-contents_lbox {
        border-radius: 23px;
        width: 225px;
        height: 225px;
        margin-top: 9.7em;
    }

    .unimy__networking-contents_lbox h2 {
        font-size: 34px;
        line-height: 45px;
    }

    .unimy__networking-contents_lbox:focus-within {
        border-radius: 23px;
        width: 520px;
        height: 820px; 
    }

    .unimy__networking-contents_lbox:focus-within h2 {
        width: 400px;
        padding-right: 0em;
        padding-left: 1em;
        padding-bottom: 1em;
    }

    .unimy__networking-contents_lbox:focus-within p {
        font-size: 24px;
        line-height: 32px;
        width: 520px;
    } 
}

@media screen and (max-width: 850px) {

    .unimy__networking-contents_lbox {
        border-radius: 17px;
        width: 167px;
        height: 167px;
        margin-top: 7.2em;
    }

    .unimy__networking-contents_lbox h2 {
        font-size: 25px;
        line-height: 33px;
    }

    .unimy__networking-contents_lbox:focus-within {
        border-radius: 17px;
        width: 410px;
        height: 600px; 
        padding-left: 24px;
        padding-right: 24px;
    }

    .unimy__networking-contents_lbox:focus-within h2 {
        width: 200px;
        padding-right: 0em;
        padding-left: 4em;
    }

    .unimy__networking-contents_lbox:focus-within p {
        font-size: 18px;
        line-height: 23px;
        width: 410px;
    } 
}

@media screen and (max-width: 650px) {

    .unimy__networking-contents_lbox {
        border-radius: 10px;
        width: 100px;
        height: 100px;
        margin-top: 4.3em;
    }

    .unimy__networking-contents_lbox h2 {
        font-size: 15px;
        line-height: 20px;
    }

    .unimy__networking-contents_lbox:focus-within {
        border-radius: 10px;
        width: 260px;
        height: 380px; 
        padding-left: 15px;
        padding-right: 15px;
    }

    .unimy__networking-contents_lbox:focus-within h2 {
        width: 200px;
        padding-right: 0em;
        padding-left: 2em;
    }

    .unimy__networking-contents_lbox:focus-within p {
        font-size: 11px;
        line-height: 14px;
        width: 242px;
        margin: 0;
    } 
}

@media screen and (max-width: 490px) {

    .unimy__networking-contents_lbox {
        border-radius: 8px;
        width: 75px;
        height: 75px;
        margin-top: 3.2em;
    }

    .unimy__networking-contents_lbox h2 {
        font-size: 11px;
        line-height: 15px;
    }

    .unimy__networking-contents_lbox:focus-within {
        border-radius: 10px;
        width: 205px;
        height: 250px; 
        padding-left: 11px;
        padding-right: 11px;
    }

    .unimy__networking-contents_lbox:focus-within h2 {
        width: 200px;
        padding-right: 0em;
        padding-left: .6em;
    }

    .unimy__networking-contents_lbox:focus-within p {
        font-size: 8px;
        line-height: 11px;
        width: 205px;
    } 
}