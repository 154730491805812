.unimy__chisiamo {
    display: flex;

}

.unimy__chisiamo-contents {
    flex: 1;
    display: flex;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 2.08%, rgba(255, 255, 255, 0.95) 10.94%,
    rgba(255, 255, 255, 0.95) 86.98%, rgba(255, 255, 255, 0) 100%);
    align-items: flex-start;
    flex-direction: column;
    height: 596px;
    padding-top: 150px;
    padding-bottom: 100px;
    width: 1280px;

}

.unimy__chisiamo-contents h1 {
    font-family: 'Futura', Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    /* identical to box height */
    text-align: center;
    color: #1664B4;

    margin-left: 1em;
}

.unimy__chisiamo-contents p {
    font-family: 'Futura', Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 42px;
    color: #00376F;
    margin: 1em;
    text-align: justify;
    text-justify: inter-word;
    padding-right: 122px;
}

@media screen and (max-width: 1050px) {

    .unimy__chisiamo-contents {
        height: 447px;
        padding-top: 90px;
        width: 960px;
    }

    .unimy__chisiamo-contents h1 {
        font-size: 27px;
        line-height: 36px;
        flex-direction: column;
    }

    .unimy__chisiamo-contents p {
        font-size: 24px;
        line-height: 31.5px;
        padding-right: 92px;
    }
}

@media screen and (max-width: 850px) {

    .unimy__chisiamo-contents {
        height: 331px;
        padding-top: 83px;
        width: 711px;
    }

    .unimy__chisiamo-contents h1 {
        font-size: 20px;
        line-height: 27px;
        flex-direction: column;
    }

    .unimy__chisiamo-contents p {
        font-size: 18px;
        line-height: 23px;
        padding-right: 68px;
    }
}

@media screen and (max-width: 650px) {

    .unimy__chisiamo-contents {
        height: 199px;
        padding-top: 50px;
        width: 427px;
    }

    .unimy__chisiamo-contents h1 {
        font-size: 12px;
        line-height: 16px;
        flex-direction: column;
    }

    .unimy__chisiamo-contents p {
        font-size: 11px;
        line-height: 14px;
        padding-right: 41px;
    }
}

@media screen and (max-width: 490px) {

    .unimy__chisiamo-contents {
        height: 100px;
        padding-top: 38px;
        width: 320px;
        height: 75px;
    }

    .unimy__chisiamo-contents h1 {
        font-size: 9px;
        line-height: 12px;
        flex-direction: column;
    }

    .unimy__chisiamo-contents p {
        font-size: 8px;
        line-height: 10.5px;
        padding-right: 31px;
    }

}