.unimy__header{
    display: flex;
}

.unimy__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: flex-start;

}

.unimy__header-content h1 {

    margin: 0;
    font-family: 'Futura', Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 360px;
    line-height: 360px;

    color: #FFFF;
}

.unimy__header-content h3 {
    margin: 0;
    margin-right: 4.5em;
    font-family: 'Futura', Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 60px;
    /* identical to box height */
    text-align: flex-start;

    color: #FFFFFF;

}

@media screen and (max-width: 1050px) {
    .unimy__header h1 {
        font-size: 270px;
        line-height: 270px;
    }

    .unimy__header h3 {
        font-size: 34px;
        line-height: 45px;
    }

}

@media screen and (max-width: 850px) {
    .unimy__header h1 {
        font-size: 200px;
        line-height: 200px;
    }

    .unimy__header h3 {
        font-size: 25px;
        line-height: 33.5px;

    }
}


@media screen and (max-width: 650px) {
    .unimy__header h1 {
        font-size: 120px;
        line-height: 120px;
    }

    .unimy__header h3 {
        font-size: 15px;
        line-height: 20px;
    }
}

@media screen and (max-width: 490px) {
    .unimy__header h1 {
        font-size: 90px;
        line-height: 95px;
    }

    .unimy__header h3 {
        font-size: 11px;
        line-height: 15px;
    }


}
